<template>
  <div class="sign-contract">
    <Button block size="large" @click="showModal">{{
      $t('VIEW_LOAN_CONTRACT')
    }}</Button>

    <b-modal
      :ref="modalRef"
      title="Contract modal"
      size="lg"
      hide-footer
      hide-header
    >
      <button type="button" class="modal__close" @click="hideModal">
        <CloseSVG class="icon" />
      </button>
      <Margins>
        <b-row class="align-items-center">
          <b-col>
            <h4>{{ $t('LOAN_CONTRACT', 'sv') }}</h4>
          </b-col>
          <b-col cols="auto">
            <Button
              variant="ghost"
              @click="startPrint = !startPrint"
              class="hidden-print"
            >
              <PrintSVG class="icon" />
            </Button>
          </b-col>
        </b-row>

        <table class="table-parsed-data">
          <tbody>
            <tr v-for="input in parsedData" :key="input.key">
              <th>{{ input.key }}</th>
              <td>{{ input.value }}</td>
            </tr>
          </tbody>
        </table>
      </Margins>
    </b-modal>

    <PrintContent :startPrint="startPrint" :id="printId" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Button, Margins } from '@/components';
import { parseAgreementText } from '@/utils';
import PrintContent from './PrintContent';
import CloseSVG from '@/assets/images/times-circle-regular.svg';
import PrintSVG from '@/assets/images/print-solid.svg';

export default {
  name: 'SignContract',
  components: {
    Button,
    Margins,
    PrintContent,
    CloseSVG,
    PrintSVG,
  },
  data() {
    return {
      printId: 'sign-print-content',
      startPrint: false,
    };
  },
  methods: {
    showModal() {
      this.$refs[this.modalRef].show();
    },
    hideModal() {
      this.$refs[this.modalRef].hide();
    },
  },
  computed: {
    ...mapState(['loanApplicationDecision']),
    parsedData() {
      return parseAgreementText(this.loanApplicationDecision.agreementText);
    },
    modalRef() {
      return `contract-modal`;
    },
  },
};
</script>

<style lang="scss"></style>
