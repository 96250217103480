<template>
  <div class="sign-delivery-info">
    <table class="table">
      <tbody>
        <tr>
          <td>
            <h6 class="text-normal">{{ $t('LOCATION') }}:</h6>
          </td>
          <td class="text-right">
            <h6>{{ bundleOrderDeliveryInfo.deliveryStore }}</h6>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SignDeliveryInfo',
  props: {
    isFull: Boolean,
  },
  computed: {
    ...mapState(['bundleOrderDeliveryInfo']),
  },
};
</script>

<style lang="scss">
.sign-delivery-info {
  padding-left: 0;
  padding-right: 0;

  @include min-width(md) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
</style>
