<template>
  <div :id="id" class="print-content">
    <h1>{{ $t('LOAN_CONTRACT', 'sv') }}</h1>
    <table class="table-parsed-data">
      <tbody>
        <tr v-for="input in parsedData" :key="input.key">
          <th>{{ input.key }}</th>
          <td>{{ input.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import printJS from 'print-js';
import { constants } from '@/mixins';
import { parseAgreementText } from '@/utils';

export default {
  name: 'PrintContent',
  mixins: [constants],
  props: {
    id: {
      type: String,
      required: true,
    },
    startPrint: Boolean,
  },
  watch: {
    startPrint() {
      printJS({
        printable: this.id,
        type: 'html',
        documentTitle: this.$t('LOAN_CONTRACT'),
        style: this.style,
      });
    },
  },
  computed: {
    ...mapState(['loanApplicationDecision']),
    style() {
      return `
        .print-content {
          display: block !important;
        }

        * {
          font-family: "Helvetica Neue", sans-serif;
        }

        h1 {
          font-size: 20px;
          margin-bottom: 20px;
        }

        h3 {
          font-size: 14px;
        }

        p {
          font-size: 10px;
          margin: 0;
          margin-bottom: 6px;
        }

        .table-parsed-data,
        .table-data {
          border-collapse: collapse;
          font-size: 14px;
          margin-bottom: 20px;
        }

        .table-parsed-data th {
          border: 1px solid transparent;
          border-right: 1px solid #76d6d3;
          text-align: left;
          padding-right: 10px;
          font-weight: normal;
          font-size: 12px;
        }

        .table-parsed-data td {
          border: 1px solid #76d6d3;
          padding-left: 10px;
          padding-top: 6px;
          padding-bottom: 6px;
        }

        .table-data {
          margin-bottom: 20px;
        }

        .table-data td {
          border: 1px solid #76d6d3;
          padding: 6px;
          width: 100%;
        }

        label {
          font-size: 14px;
        }
      `;
    },
    parsedData() {
      return parseAgreementText(this.loanApplicationDecision.agreementText);
    },
  },
};
</script>

<style lang="scss">
.print-content {
  display: none;
}
</style>
